import React, { useEffect, useState, useContext } from 'react'
import { auth, firestore } from '../firebase.js'

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {

    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)
    

    function signup(email, password) {
        return auth.createUserWithEmailAndPassword(email, password)
    }

    function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password)
    }

    function logout() {
        return auth.signOut()
    }

    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email)
    }

    function updateEmail(email) {
        return currentUser.updateEmail(email)
    }

    function updatePassword(password) {
        return currentUser.updatePassword(password)
    }

    function addPlayer(firstName, lastName, currentUid) {

        return firestore.collection('users').doc(currentUid).collection('players').add({
            firstName: firstName,
            lastName: lastName
        })
    }

    function deletePlayer(playerId, currentUid) {

        return firestore
            .collection('users')
            .doc(currentUid)
            .collection('players')
            .doc(playerId)
            .delete()
    }

    function addSession(sessionData, currentUid) {
        return firestore
            .collection('users')
            .doc(currentUid)
            .collection('sessions')
            .add({
                createdAtDisplay: new Date(Date.now()).toUTCString(),
                timeDiff: new Date(Date.now()).getTimezoneOffset(),
                createdAt: Date.now(),
                sessionDescription: '',
                sessionData: sessionData
            })
    }

    function getSession(sessionId, currentUid) {
        return firestore
            .collection('users')
            .doc(currentUid)
            .collection('sessions')
            .doc(sessionId)
            .get()
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
        })

        return unsubscribe
    }, [])
    

    const value = {
        currentUser,
        login,
        signup,
        logout,
        resetPassword,
        updateEmail,
        updatePassword,
        addPlayer,
        deletePlayer,
        addSession,
        getSession
    }

    return(
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}