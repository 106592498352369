import React, { useRef, useState, useEffect } from 'react'
import { Container, Grid, IconButton, TextField, Box, Avatar, List } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { Link } from 'react-router-dom'
import Copyright from './Copyright.js'
import { Alert } from '@material-ui/lab'
import { useAuth } from '../contexts/AuthContext'
import { firestore } from '../firebase.js'
import RosterItem from './RosterItem.js'

function useRoster() {
  const [roster, setRoster] = useState([])
  const { currentUser } = useAuth()

  useEffect(() => {
    firestore
      .collection('users')
      .doc(currentUser.uid)
      .collection('players')
      .orderBy('lastName', 'asc')
      .onSnapshot((snapshot) => {
        const newRoster = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }))

        setRoster(newRoster)
      })

  }, [currentUser])

  return roster
}

export default function Roster() {
  const firstNameRef = useRef()
  const lastNameRef = useRef()
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const { currentUser, addPlayer } = useAuth()

  const roster = useRoster()

  async function handleAddPlayer(e) {

    e.preventDefault()

    try {
      setError('')
      setMessage('')
      setLoading(true)
      await addPlayer(firstNameRef.current.value, lastNameRef.current.value, currentUser.uid)
      setMessage(firstNameRef.current.value + ' ' + lastNameRef.current.value + ' successfully added')
      document.getElementById('rosterAddFirstName').value=''
      document.getElementById('rosterAddLastName').value=''
      document.getElementById('rosterAddFirstName').focus()
    } catch {
      setError('Failed to add player')
    }
    setLoading(false)
  }

  return(
        <Container component='main' maxWidth='xs'>
            <Grid container spacing={1} alignItems='center' justify='spaceBetween'>
                <Grid item xs={6}>
                    <h2>Roster</h2>
                </Grid>
                <Grid item xs={6}>
                  <Link to='/'>Back to Profile</Link>
                </Grid>
                <Grid item xs={12}>
                  <Alert severity='info'>Roster size is capped at 100 during the closed beta</Alert>
                </Grid>
                <Grid item xs={12}>
                  {error && <Alert severity='error'>{error}</Alert>}
                  {message && <Alert severity='success'>{message}</Alert>}
                </Grid>
                <form onSubmit={handleAddPlayer}>
                <Grid container item xs={12} alignItems='center' spacing={1} justifyContent='space-between'>
                  <Grid item xs={5}>
                    <TextField id='rosterAddFirstName' variant='outlined' label='First Name' type='text' inputRef={firstNameRef} fullWidth required />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField id='rosterAddLastName' variant='outlined' label='Last Name' type='text' inputRef={lastNameRef} fullWidth required />
                  </Grid>
                  <Grid item xs={2} align='center'>
                    <IconButton color='primary' type='submit' disabled={loading}><Avatar style={{ backgroundColor: '#3f51b5' }}><AddCircleIcon /></Avatar></IconButton>
                  </Grid>
                  <Grid item xs={12}>
                    {roster.length === 0 && <Alert severity='warning'>Add a player to begin building your roster.</Alert>}
                  </Grid>
                </Grid>
                </form>
                <Grid container item xs={12} alignItems='center' spacing={1}>
                  <List dense xs={12} style={{flexGrow: '1'}}>
                      {roster.map((player) => 
                      <RosterItem 
                        firstName={player.firstName}
                        lastName={player.lastName}
                        playerId={player.id} />
                      )}
                  </List>
                </Grid>
            </Grid>
            
            
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    )
}