import React, { useRef, useState } from 'react'
import { Button, Grid, TextField, Container, FormControlLabel, Checkbox, Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useAuth } from '../contexts/AuthContext.js'
import { Link, useHistory } from 'react-router-dom'
import Copyright from './Copyright.js'

export default function Signup() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const jargonAgreementRef = useRef()
    const { signup } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    async function handleSubmit(e) {
        e.preventDefault()

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Passwords do not match')
        }

        if (jargonAgreementRef.current.checked === false) {
            return setError('Seriously, no jargon vomit')
        }

        try {
            setError('')
            setLoading(true)
            await signup(emailRef.current.value, passwordRef.current.value)
            history.push('/')
        } catch {
            setError('Failed to create an account')
        }

        setLoading(false)
        
    } 

    return (
        <Container component='main' maxWidth='xs'>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} align='center'>
                        <h1>Sign Up</h1>
                    </Grid>
                    <Grid item xs={12}>
                        {error && <Alert severity='error'>{error}</Alert>}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id='email' variant='outlined' label='Email' type='email' inputRef={emailRef} required fullWidth autoComplete='email'/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id='password' variant='outlined' label='Password' type='password' inputRef={passwordRef} required fullWidth autoComplete='current-password' />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id='password-confirm' variant='outlined' label='Confirm Password' type='password' inputRef={passwordConfirmRef} required fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox value="allowExtraEmails" color="primary" inputRef={jargonAgreementRef} />}
                            label='I will refrain from vomiting jargon at my players.'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button id='signup' variant='contained' color='primary' disabled={loading} type='submit' fullWidth size='large'>
                            Sign Up
                        </Button>
                    </Grid>
                    
                </Grid>
                
            </form>
            <Grid container spacing={2}>
                <Grid item align='center' xs={12}>
                    <br />
                    <Link to='/login'>
                        Already have an account? Log in
                    </Link>
                </Grid>
            </Grid>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    )
}