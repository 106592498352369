import React, { useRef, useState } from 'react'
import { Button, Grid, TextField, Container, Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useAuth } from '../contexts/AuthContext.js'
import { Link, useHistory } from 'react-router-dom'
import Copyright from './Copyright.js'

export default function UpdateProfile() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const { currentUser, updateEmail, updatePassword } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    function handleSubmit(e) {
        e.preventDefault()

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Passwords do not match')
        }

        const promises = []
        setLoading(true)
        setError('')

        if (emailRef.current.value !== currentUser.email) {
          promises.push(updateEmail(emailRef.current.value))
        }
        if (passwordRef.current.value) {
          promises.push(updatePassword(passwordRef.current.value))
        }

        Promise.all(promises).then(() => {
          history.push('/')
        }).catch(() => {
          setError('Failed to update account')
        }).finally(() => {
          setLoading(false)
        })
        
    } 

    return (
        <Container component='main' maxWidth='xs'>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} align='center'>
                        <h1>Update Profile</h1>
                    </Grid>
                    <Grid item xs={12}>
                        {error && <Alert severity='error'>{error}</Alert>}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id='email' variant='outlined' label='Email' type='email' inputRef={emailRef} defaultValue={currentUser.email} required fullWidth autoComplete='email'/>
                    </Grid>
                    <Grid item xs={12}>
                      <p>To keep your current password, leave the following two fields blank.</p>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id='password' variant='outlined' label='New Password' type='password' inputRef={passwordRef} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id='password-confirm' variant='outlined' label='Confirm New Password' type='password' inputRef={passwordConfirmRef} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <Button id='signup' variant='contained' color='primary' disabled={loading} type='submit' fullWidth size='large'>
                            Update
                        </Button>
                    </Grid>
                </Grid>
                
            </form>
            <Grid container spacing={2}>
                    <Grid item align='center' xs={12}>
                        <Link to='/'>
                            Cancel
                        </Link>
                    </Grid>
            </Grid>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    )
}