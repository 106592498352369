import React from 'react'
import { Avatar, IconButton, ListItemText, ListItem, ListItemAvatar, ListItemSecondaryAction } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { useAuth } from '../contexts/AuthContext'


export default function RosterItem({ firstName, lastName, playerId }) {

  const { currentUser, deletePlayer } = useAuth()

  async function handleDelete(e) {
    e.preventDefault()
    await deletePlayer(e.currentTarget.dataset.playerid, currentUser.uid)
  }

  return (

    <ListItem divider >
      <ListItemAvatar>
        <Avatar>{firstName.charAt(0).toUpperCase()}{lastName.charAt(0).toUpperCase()}</Avatar>
      </ListItemAvatar>
      <ListItemText
          primary={lastName + ', ' + firstName}
      />
      <ListItemSecondaryAction>
        <IconButton data-playerid={playerId} edge='end' aria-label='delete' onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
