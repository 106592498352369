import React, { useRef, useState } from 'react'
import { Button, Container, TextField, Grid, Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useAuth } from '../contexts/AuthContext.js'
import { Link } from 'react-router-dom'
import Copyright from './Copyright.js'

export default function ForgotPassword() {
    const emailRef = useRef()
    const { resetPassword } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setMessage('')
            setError('')
            setLoading(true)
            await resetPassword(emailRef.current.value)
            setMessage('Check your inbox for further instructions')
        } catch {
            setError('Failed to reset password')
        }

        setLoading(false)
        
    } 

    return (
        <>
            <Container component='main' maxWidth='xs'>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} align='center'>
                        <h1>Reset Password</h1>
                    </Grid>
                    <Grid item xs={12}>
                        {error && <Alert severity='error'>{error}</Alert>}
                        {message && <Alert severity='success'>{message}</Alert>}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id='email' variant='outlined' label='Email' type='email' inputRef={emailRef} required fullWidth autoComplete='email' autoFocus />
                    </Grid>
                    <Grid item xs={12}>
                        <Button id='resetPassword' variant='contained' color='primary' disabled={loading} type='submit' fullWidth size='large'>
                            Reset Password
                        </Button>
                    </Grid>
                    <Grid item xs={12} align='center'>
                        <Link to='/login'>Log In</Link>
                    </Grid>
                </Grid>
            </form>
            <Grid container spacing={2}>
                    <Grid item xs={12} align='center'>
                        <Link to='/signup'>
                            Need an account? Sign up
                        </Link>
                    </Grid>
            </Grid>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
        </>
    )
}