import React, { useRef, useState } from 'react'
import { Button, Container, TextField, Grid, Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useAuth } from '../contexts/AuthContext.js'
import { Link, useHistory } from 'react-router-dom'
import Copyright from './Copyright.js'

export default function Login() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const { login } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setError('')
            setLoading(true)
            await login(emailRef.current.value, passwordRef.current.value)
            history.push('/')
        } catch {
            setError('Failed to log in')
        }

        setLoading(false)
        
    } 

    return (
        
            <Container component='main' maxWidth='xs'>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} align='center'>
                        <h1>Log In</h1>
                    </Grid>
                    <Grid item xs={12}>
                        {error && <Alert severity='error'>{error}</Alert>}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id='email' variant='outlined' label='Email' type='email' inputRef={emailRef} required fullWidth autoComplete='email' autoFocus />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id='password' variant='outlined' label='Password' type='password' inputRef={passwordRef} required fullWidth autoComplete='current-password' />
                    </Grid>
                    <Grid item xs={12}>
                        <Button id='login' variant='contained' color='primary' disabled={loading} type='submit' fullWidth size='large'>
                            Log In
                        </Button>
                    </Grid>
                    <Grid item xs={12} align='center'>
                        <br />
                        <Link to='/forgot-password'>Forgot Password?</Link>
                    </Grid>
                </Grid>
            </form>
            <Grid container spacing={2}>
                    <Grid item xs={12} align='center'>
                        <br />
                        <Link to='/signup'>
                            Need an account? Sign up
                        </Link>
                    </Grid>
            </Grid>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
        
    )
}