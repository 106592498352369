import React, { useState, useEffect } from 'react'
import { Container, Grid, Button, Box, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import ViewListIcon from '@material-ui/icons/ViewList'
import { Link } from 'react-router-dom'
import Copyright from './Copyright.js'
import { useAuth } from '../contexts/AuthContext'
import { firestore } from '../firebase.js'

function useSessions() {
  const [sessions, setSessions] = useState([])
  const { currentUser } = useAuth()

  useEffect(() => {
    firestore
      .collection('users')
      .doc(currentUser.uid)
      .collection('sessions')
      .orderBy('createdAt', 'desc')
      .onSnapshot((snapshot) => {
        const newSessions = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }))

        setSessions(newSessions)
      })

  }, [currentUser])

  return sessions
}

export default function Sessions() {

  const sessions = useSessions()
  const { currentUser, getSession } = useAuth()
  const [leaderboard, setLeaderboard] = useState([])
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  async function handleGetSession(e) {
    e.preventDefault()
    await getSession(e.currentTarget.dataset.sessionid, currentUser.uid).then(function(doc) {
      const data = doc.data()
      const keys = Object.keys(data.sessionData)
      const newData = []
      keys.forEach(key => {
        newData.push({
          playerName: data.sessionData[key].playerName,
          successes: data.sessionData[key].scoreList.reduce((a,b) => a+b, 0),
          total: data.sessionData[key].scoreList.length,
          percent: Math.round(
            10000 * data.sessionData[key].scoreList.reduce((a,b) => a+b, 0)/
            data.sessionData[key].scoreList.length
          )/100
        })
      })
      setLeaderboard(newData.sort((a,b) => parseFloat(b.percent) - parseFloat(a.percent)))
    })
    setOpen(true)
  }

    return(
        <Container component='main' maxWidth='xs'>
            <Grid container spacing={1} alignItems='center' justify='spaceBetween'>
                <Grid item xs={6}>
                    <h2>Sessions</h2>
                </Grid>
                <Grid item xs={6}>
                  <Link to='/'>Back to Profile</Link>
                </Grid>
                <Grid item xs={12}>
                  <Link to='/new-session' style={{ textDecoration: 'none' }}>
                    <Button size='large' variant='contained' color='primary' startIcon={<AddCircleIcon />} fullWidth>
                      New Session
                    </Button>
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <List dense style={{flexGrow: '1'}}>
                    {sessions.map((session) => 
                      <ListItem divider >
                        {/* <ListItemAvatar>
                          <Avatar>{firstName.charAt(0).toUpperCase()}{lastName.charAt(0).toUpperCase()}</Avatar>
                        </ListItemAvatar> */}
                        <ListItemText
                            // primary={session.createdAtDisplay}
                            primary={new Date(session.createdAt - session.timeDiff*60000).toUTCString().slice(0,-4)}
                        />
                        <ListItemSecondaryAction>
                          <IconButton data-sessionid={session.id} edge='end' aria-label='view' onClick={handleGetSession}>
                            <ViewListIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    )}
                  </List>
                </Grid>
                <Dialog open={open} onClose={handleClose} scroll='body' fullWidth>
                  <DialogActions>
                    <Button onClick={handleClose} variant='outlined'>
                        Close
                    </Button>
                  </DialogActions>
                  <DialogTitle>
                    Session Leaderboard
                  </DialogTitle>
                  <DialogContent>
                    <List dense style={{flexGrow: '1'}}>
                      {leaderboard.map((row) =>
                        <ListItem divider>
                          <ListItemText
                          primary={row.playerName}
                          secondary={row.percent + '% (' + row.successes + ' for ' + row.total + ')'} />
                        </ListItem>
                      )}
                    </List>
                  </DialogContent>
                </Dialog>
            </Grid>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    )
}