import React from 'react'
import './App.css'
import Signup from './Signup.js'
import Dashboard from './Dashboard.js'
import Login from './Login.js'
import { Box } from '@material-ui/core'
import { AuthProvider } from '../contexts/AuthContext.js'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import PrivateRoute from './PrivateRoute.js'
import ForgotPassword from './ForgotPassword.js'
import UpdateProfile from './UpdateProfile.js'
import Roster from './Roster.js'
import Sessions from './Sessions.js'
import NewSession from './NewSession.js'

function App() {
  return (
    <AuthProvider>
      <Box>
        <Router>
          <Switch>
            <PrivateRoute exact path='/' component={Dashboard} />
            <PrivateRoute exact path='/update-profile' component={UpdateProfile} />
            <PrivateRoute exact path='/roster' component={Roster} />
            <PrivateRoute exact path='/sessions' component={Sessions} />
            <PrivateRoute exact path='/new-session' component={NewSession} />
            <Route path='/signup' component={Signup} />
            <Route path='/login' component={Login} />
            <Route path='/forgot-password' component={ForgotPassword} />
          </Switch>
        </Router>
      </Box>
    </AuthProvider>
      
  )
}

export default App