import React, { useState } from 'react'
import { Button, Container, Grid, Box, Typography } from '@material-ui/core'
import { useAuth } from '../contexts/AuthContext'
import { Link, useHistory } from 'react-router-dom'
import { Alert } from '@material-ui/lab'
import Copyright from './Copyright.js'

export default function Dashboard() {
    const [error, setError] = useState('')
    const { currentUser, logout } = useAuth()
    const history = useHistory()

    async function handleLogout() {
        setError('')

        try {
            await logout()
            history.push('/login')
        } catch {
            setError('Failed to log out')
        }
    }

    return(
        <Container component='main' maxWidth='xs'>
            <Grid container spacing={2} alignItems='center'>
                <Grid item xs={12} align='center'>
                    <Typography variant='h1'>
                        Pollice Verso
                    </Typography>
                    <Typography variant='subtitle1'>
                        Without results, what good is your process?
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Alert severity='info'>
                        You are signed in as {currentUser.email}
                    </Alert>
                    {error && <Alert severity='error' fullWidth>{error}</Alert>}
                </Grid>
                <Grid item xs={12}>
                    <Link to='/update-profile'>Update Profile</Link>
                </Grid>
                <Grid item xs={12}>
                    <Link to='/roster'>My Roster</Link>
                </Grid>
                <Grid item xs={12}>
                    <Link to='/sessions'>My Sessions</Link>
                </Grid>
                <Grid item xs={12}>
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth color='primary' variant='contained' size='large' onClick={handleLogout}>Log Out</Button>
                </Grid>
            </Grid>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    )
}