import React, { useState, useEffect } from 'react'
import { Container, Grid, Box, Select, Button, FormControl, InputLabel, Avatar, Typography, Dialog, DialogActions, DialogTitle, DialogContent, List, ListItem, ListItemText } from '@material-ui/core'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import { Link, useHistory } from 'react-router-dom'
import Copyright from './Copyright.js'
import SaveIcon from '@material-ui/icons/Save'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { useAuth } from '../contexts/AuthContext'
import { firestore } from '../firebase.js'
import ViewListIcon from '@material-ui/icons/ViewList'

function useRoster() {
  const [roster, setRoster] = useState([])
  const { currentUser } = useAuth()
  

  useEffect(() => {
    firestore
      .collection('users')
      .doc(currentUser.uid)
      .collection('players')
      .orderBy('lastName', 'asc')
      .onSnapshot((snapshot) => {
        const newRoster = snapshot.docs.map((doc) => ({
          playerId: doc.id,
          ...doc.data()
        }))

        setRoster(newRoster)
      })

  }, [currentUser])

  return roster
}

export default function NewSession() {

  const roster = useRoster()
  const [scores, setScores] = useState([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { currentUser, addSession } = useAuth()
  const history = useHistory()
  const [selected, setSelected] = useState('')
  const [leaderboard, setLeaderboard] = useState([])
  const [open, setOpen] = useState(false)

  function handleThumbUp(e) {
    e.preventDefault()
    setScores([...scores, {playerId: document.getElementById('selectPlayer').value, score: 1, playerName: document.getElementById('selectPlayer').options[document.getElementById('selectPlayer').selectedIndex].dataset.playername}])
  }

  function handleThumbDown(e) {
    e.preventDefault()
    setScores([...scores, {playerId: document.getElementById('selectPlayer').value, score: 0, playerName: document.getElementById('selectPlayer').options[document.getElementById('selectPlayer').selectedIndex].dataset.playername}])
  }

  function handleSelectChange(e) {
    e.preventDefault()
    setSelected(e.target.value)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    const displayScores = scores.reduce((a, c) => {
      (a[c.playerId] || (a[c.playerId] = {playerName: c.playerName, scoreList: []})).scoreList.push(c.score);
      return a;
      }, {})
    const keys = Object.keys(displayScores)
    const newData = []
      keys.forEach(key => {
        newData.push({
          playerName: displayScores[key].playerName,
          successes: displayScores[key].scoreList.reduce((a,b) => a+b, 0),
          total: displayScores[key].scoreList.length,
          percent: Math.round(
            10000 * displayScores[key].scoreList.reduce((a,b) => a+b, 0)/
            displayScores[key].scoreList.length
          )/100
        })
      })
    setLeaderboard(newData.sort((a,b) => parseFloat(b.percent) - parseFloat(a.percent)))
    setOpen(true)
  }

  async function handleAddSession(e) {
    e.preventDefault()

    try {
      setError('')
      setLoading(true)
      const arrayedScores = await scores.reduce((a, c) => {
        (a[c.playerId] || (a[c.playerId] = {playerName: c.playerName, scoreList: []})).scoreList.push(c.score);
        return a;
        }, {})
      await addSession(arrayedScores, currentUser.uid)
      history.push('/sessions')
    } catch {
      setError('Failed to save session')
    }
    setLoading(false)
  }

    return(
      <Container component='main' maxWidth='xs'>
      <Grid container spacing={1} alignItems='center' justify='spaceBetween'>
          <Grid item xs={12} align='center'>
              <h2>Current Session</h2>
          </Grid>
          <Grid item xs={12}>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant='outlined'>
              <InputLabel>Player</InputLabel>
              <Select native id='selectPlayer' onChange={handleSelectChange}>
                <option value='nullId' label='Player' data-playername='NO PLAYER SELECTED'>Player</option>
              {roster.map((player) => 
                    <option 
                      label={player.lastName + ', ' + player.firstName}
                      value={player.playerId}
                      data-playername={player.lastName + ', ' + player.firstName}
                    >{player.lastName + ', ' + player.firstName}</option>
                    )}
              </Select>
              
              <Grid container spacing={4} alignItems='center' justify='spaceBetween'>
                <Grid item xs={12}>
                  <br />
                </Grid>
                <Grid item xs={12} align='center' >
                <Button onClick={handleOpen} variant='outlined' startIcon={<ViewListIcon />}>
                        Live Leaderboard
                    </Button>
                </Grid>
                <Grid item xs={12} align='center'>
                  <Typography variant='h1'>
                    {scores.filter((record) => record.playerId === document.getElementById('selectPlayer').value).length > 0 && Math.round(100*(scores.filter((record) => record.playerId === document.getElementById('selectPlayer').value).reduce(function (r, a) {return r + a['score']}, 0))/(scores.filter((record) => record.playerId === document.getElementById('selectPlayer').value).length))}{'%'}
                  </Typography>
                  {scores.filter((record) => record.playerId === document.getElementById('selectPlayer').value).reduce(function (r, a) {return r + a['score']}, 0)}
                  {' for '}
                  {scores.filter((record) => record.playerId === document.getElementById('selectPlayer').value).length}
                </Grid>
                <Grid item xs={6}>
                  <Button onClick={handleThumbDown} style={{backgroundColor: 'salmon', color: 'darkred', fontSize: '40px'}} fullWidth size='large' variant='outlined' startIcon={<ThumbDownIcon style={{fontSize: '80px'}} />}>
                    
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button onClick={handleThumbUp} style={{backgroundColor: 'lightgreen', color: 'darkgreen', fontSize: '40px'}} fullWidth size='large' variant='outlined' startIcon={<ThumbUpIcon style={{fontSize: '80px'}} />}>
                    
                  </Button>
                </Grid>
                
                <Grid container item xs={12} direction='row' display='none'>
                    {scores.filter((record) => record.playerId === document.getElementById('selectPlayer').value).map(
                      (rep) =>
                      <Grid item xs={1}>
                          <Avatar style={rep.score === 1 ? {backgroundColor: 'lightgreen', color: 'darkgreen'} : {backgroundColor: 'salmon', color: 'darkred'}}>
                            {rep.score === 1 ? <ThumbUpIcon /> : <ThumbDownIcon />}
                          </Avatar>
                          </Grid>
                    )}
                </Grid>
                <Grid item xs={12}>

                </Grid>
              </Grid>
            </FormControl>
          
          </Grid>
          
          <Grid item xs={12}>
            <Button onClick={handleAddSession} fullWidth size='large' variant='contained' color='primary' startIcon={<SaveIcon />}>Save Session</Button>
          </Grid>
          <Grid item xs={12}>
            <Link to='/sessions' style={{ textDecoration: 'none' }}>
              <Button fullWidth size='large' variant='outlined' color='secondary' startIcon={<HighlightOffIcon />}>Exit Session</Button>
            </Link>
          </Grid>
          
      </Grid>
      <Dialog open={open} onClose={handleClose} scroll='body' fullWidth>
                  <DialogActions>
                    <Button onClick={handleClose} variant='outlined'>
                        Close
                    </Button>
                  </DialogActions>
                  <DialogTitle>
                    Live Leaderboard
                  </DialogTitle>
                  <DialogContent>
                    <List dense style={{flexGrow: '1'}}>
                      {leaderboard.map((row) =>
                        <ListItem divider>
                          <ListItemText
                          primary={row.playerName}
                          secondary={row.percent + '% (' + row.successes + ' for ' + row.total + ')'} />
                        </ListItem>
                      )}
                    </List>
                  </DialogContent>
                </Dialog>
      <Box mt={5}>
          <Copyright />
      </Box>
  </Container>
    )
}